// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-news-item-news-item-jsx": () => import("/opt/build/repo/src/templates/NewsItem/newsItem.jsx" /* webpackChunkName: "component---src-templates-news-item-news-item-jsx" */),
  "component---src-templates-game-item-game-item-jsx": () => import("/opt/build/repo/src/templates/GameItem/gameItem.jsx" /* webpackChunkName: "component---src-templates-game-item-game-item-jsx" */),
  "component---src-templates-bet-site-bet-site-jsx": () => import("/opt/build/repo/src/templates/BetSite/betSite.jsx" /* webpackChunkName: "component---src-templates-bet-site-bet-site-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-betting-sites-jsx": () => import("/opt/build/repo/src/pages/betting-sites.jsx" /* webpackChunkName: "component---src-pages-betting-sites-jsx" */),
  "component---src-pages-esports-betting-jsx": () => import("/opt/build/repo/src/pages/esports-betting.jsx" /* webpackChunkName: "component---src-pages-esports-betting-jsx" */),
  "component---src-pages-games-jsx": () => import("/opt/build/repo/src/pages/games.jsx" /* webpackChunkName: "component---src-pages-games-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("/opt/build/repo/src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */)
}

